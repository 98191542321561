<template>
  <a-modal
    wrapClassName="sign-modal"
    :width="700"
    :bodyStyle="{ padding: 0 }"
    :centered="true"
    :maskClosable="false"
    :footer="null"
    :visible="visible"
    @cancel="cancel"
  >
    <div class="sign">
      <div class="status">
        <div class="top">{{ $t('mine.cumulative_signin', [always]) }}</div>
        <!-- 本月累计签到 天 -->
        <div class="bottom">
          <div class="img">
            <img src="@/assets/image/signIn.jpg" />
          </div>
          <div class="info">{{ $t('XB_SignInTodayGet') }} {{ integral }} {{ $t('LB_Pro_Integral') }}</div>
          <!-- 今日签到获得 积分 -->
          <div class="btns">
            <a-button type="primary" block>{{
              !isSign ? $t('mine.sign_in') : $t('Pub_Btn_AlreadySign')
            }}</a-button>
            <!-- "签到中" : "已签到" -->
          </div>
        </div>
      </div>
      <div class="calendar">
        <div class="box">
          <Calendar
            :choosable="false"
            :highlightDates="signDates"
            @monthChange="monthChange"
          />
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import Calendar from "@/components/Calendar.vue";
import { sign, signList, userInfo } from "@/api/user";

export default defineComponent({
  components: {
    Calendar,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const state = reactive({
      visible: false,
      signDates: [],
      isSign: false,
      always: 0,
      integral: 0,
    });

    const getSignList = (mon, toSign) => {
      signList({ keyword: mon }).then((res) => {
        let d = res.data || [];
        state.signDates = d.map((item) => {
          return moment(item.createTime * 1000).format("YYYY-MM-DD");
        });
        state.always = d.length;
        if (
          toSign &&
          !state.signDates.includes(moment().format("YYYY-MM-DD"))
        ) {
          sign().then((res) => {
            if (res.ret === 0) {
              state.isSign = true;
              proxy.$message.success(res.data);
              getSignList(moment().format("YYYY-MM") + "-01", false);
              userInfo().then((res2) => {
                if (res2.ret === 0) {
                  store.dispatch("setUserInfo", res2.data);
                }
              });
            }
          });
        } else {
          state.isSign = true;
          if (d.length) state.integral = res.data[0].integral;
        }
      });
    };

    const show = () => {
      state.visible = true;
      getSignList(moment().format("YYYY-MM") + "-01", true);
    };

    const cancel = () => {
      state.visible = false;
    };

    const monthChange = (date) => {
      getSignList(date.year + "-" + date.month + "-01", false);
    };

    return {
      ...toRefs(state),
      show,
      cancel,
      monthChange,
    };
  },
});
</script>
<style lang="less" scoped>
.sign {
  .mixinFlex(space-between);
  .status {
    width: 220px;
    background-color: #f5f5f5;
    .top {
      height: 90px;
      background-color: #efecec;
      color: #333;
      .mixinFlex(center; center);
      position: relative;
      padding: 0 10px;
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #efecec;
        position: absolute;
        bottom: -5px;
        left: calc(50% - 5px);
        transform: rotate(45deg);
      }
    }
    .bottom {
      padding: 0 30px 40px;
      .img {
        .mixinImgWrap(153px; 100px);
        margin: 44px auto 0;
      }
      .info {
        margin-top: 36px;
        line-height: 24px;
        background-color: #fff;
        border-radius: 4px;
        padding: 4px 10px;
      }
      .btns {
        margin-top: 40px;
        .ant-btn {
          border-radius: 4px;
        }
      }
    }
  }
  .calendar {
    width: 455px;
    padding: 0 30px 20px 0;
    .box {
      width: 427px;
    }
  }
}
</style>
<style lang="less">
.sign-modal {
  .ant-modal-close-x {
    width: 28px;
    height: 50px;
    line-height: 50px;
  }
}
</style>
